export const analytics = {
  get: {
    url: "dashboard/dashboardAnalytics",
    method: "GET",
  },
  heatmap: {
    url: "dashboard/dashboardHeatLocations",
    method: "GET",
  },
  heatMapLocations: {
    url: "dashboard/heatMapLocations",
    method: "GET",
    id: null,
    params: null,
  },
  admin_heatMapLocations: {
    url: "dashboard/admin/heatMapLocations",
    method: "GET",
    id: null,
    params: null,
  },
  addLocations: {
    url: "fakeData",
    method: "POST",
    id: null,
    params: null,
    data: null,
  },
};
